
    <div class="row">
        <div class="col-xs-12"style="margin-top:0px">
            <div class="box box-rojo collapsed-box">
                <div class="box-header">
                    <h3 class="box-title">Galería de imágenes</h3>
                    <div class="box-tools pull-right">
        
                        <button type="button" class="btn btn-box-tool" tooltip='agregar' (click)="irAgregar(Agregar)">
                            <i class="svg-agregar-general"></i>
                        </button>
                        <button class="btn btn-box-tool linea-divisoria ng-scope" disabled=""> | </button>
                        <button type="button" class="btn btn-box-tool" (click)="isCollapsed = !isCollapsed"
                            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
                                <i class="fa fa-minus"></i>
                        </button>
                    </div>
                </div>
                <div class="box-body" style="padding-bottom: 0px;" [collapse]="isCollapsed">
                    <div class=row>
                        <div  class="col-xs-12 col-sm-6 col-lg-2 container" *ngFor="let galeria of galeriasP" >
                            
                            <img [src]="galeria.imagen" width="150px" height="150px" class="img-responsive">
                            <p class="text-center"><button type="button" class="btn btn-link no-padding-sides" tooltip="eliminar"(click)="irEliminar(galeria.codigo,eliminar)"><span class="svg-eliminar"></span></button></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <ng-template #Agregar>
            <div class="modal-header bg-uniserse">
                <h4 class="modal-title pull-left">Agregar</h4>
                <button type="button" class="close pull-right" aria-label="Close" style="color:#ffffff" (click)="modalRef.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form #frmModificar="ngForm">
                    <div class="text-center">
                        <p>Únicamente se aceptan archivos con las siguientes extensiones .jpg || .jpeg</p>
                        <input class="form-control" type="file" 
                                (change)="onChange($event)">
                      
                        
                    </div>
                </form>
              </div>
              <div class="modal-footer">
                <div class="col-xs-12">
                    <br />
                    
                    <button type="button" class="btn btn-link" tooltip="cancelar" (click)="modalRef.hide()"><span class="svg-noaprobado"></span></button>
                    <button (click)="onUpload()" tooltip="subir archivo" class="btn btn-link"><i class="svg-descarga"></i></button>
                   
                    
                </div>
            </div>
        </ng-template>
        <ng-template #eliminar>
            <div class="modal-header bg-uniserse">
                <h4 class="modal-title pull-left">Eliminar</h4>
                <button type="button" class="close pull-right" aria-label="Close" style="color:#ffffff" (click)="modalRef.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                ¿Desea eliminar el registro seleccionado?
              </div>
              <div class="modal-footer">
                <div class="col-xs-12">
                    <br />
                    <button type="button" class="btn btn-link" tooltip="cancelar" (click)="modalRef.hide()"><span class="svg-noaprobado"></span></button>
                    <button class="btn btn-link" type="button" tooltip="aceptar" (click)="eliminarGaleria(galeria.codigo)"><span class="svg-aprobado"></span></button>
                    
                </div>
            </div>
        </ng-template>
