<section class="content-header ng-scope">

</section>
<section class="content">
<div class="row">
    <div class="col-xs-12">
        <div class="box box-rojo" style="margin-bottom:0px;">
            <div class="box-header">
                <h3 class="box-title">Productos</h3>
                <div class="box-tools pull-right">
                    <button class="btn btn-box-tool linea-divisoria ng-scope"> | </button>
                    <button type="button" class="btn btn-box-tool" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
                        <i class="fa fa-minus"></i>
                    </button>
                </div>
            </div>
            <div class="box-body" style="padding-bottom: 0px;">
                <div class="row">
                    <div class="col-xs-12 col-md-3">
                        <strong><i class="text-naranja">*</i> Modelo</strong><br>
                        <select class="form-control margen" [(ngModel)]="producto.codigoModelos" name="modelo" #modelo="ngModel" >
                            <option [ngValue]="null" disabled>Seleccione un modelo</option>
                            <option *ngFor="let modelo of modelos" [ngValue]="modelo.codigo">{{modelo.nombre}}</option>
                          </select>
                     
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <strong><i class="text-naranja">*</i> Estilo</strong><br>
                        <select class="form-control margen" [(ngModel)]="producto.codigoEstilos" name="estilo" #estilo="ngModel" >
                            <option [ngValue]="null" disabled>Seleccione un modelo</option>
                            <option *ngFor="let estilo of estilos" [ngValue]="estilo.codigo">{{estilo.nombre}}</option>
                          </select>
                      <br>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <strong><i class="text-naranja">*</i> Moneda</strong><br>
                        <select class="form-control margen" [(ngModel)]="producto.codigoMonedas" name="moneda" #moneda="ngModel" >
                            <option [ngValue]="null" disabled>Seleccione una moneda</option>
                            <option *ngFor="let moneda of monedas" [ngValue]="moneda.codigo">{{moneda.nombre}}</option>
                          </select>
                     <br>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <strong><i class="text-naranja">*</i> Identificador </strong>
                        <input type="text" class="form-control"  [(ngModel)]="producto.identificador" name="identificador" #identificador="ngModel" required>
                     <br>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <strong><i class="text-naranja">*</i> Precio </strong>
                        <input type="number" class="form-control"  [(ngModel)]="producto.precio" name="precio" #precio="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <strong><i class="text-naranja">*</i> Precio sugerido</strong>
                        <input type="number" class="form-control"  [(ngModel)]="producto.precioSugerido" name="precioSugerido" #precioSugerido="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <strong><i class="text-naranja">*</i> Kilometraje </strong>
                        <input type="number" class="form-control"  [(ngModel)]="producto.kilometraje" name="kilometraje" #kilometraje="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <strong>
                         # Puertas</strong>

                          <select class="form-control margen" [(ngModel)]="producto.puertas" name="puertas" #puertas="ngModel">
                            <option [ngValue]="null" disabled>Seleccione # puertas</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                            <br>
                      </div>
                      <div class="col-xs-12 col-md-3">
                        <strong>Combustible</strong><br>
                        <select class="form-control" [(ngModel)]="producto.combustible" name="combustible" #combustible="ngModel">
                            <option [ngValue]="null" disabled>Seleccione combustible</option>
                              <option [ngValue]="'g'">Gasolina</option>
                              <option [ngValue]="'d'">Diesel</option>
                              <option value="h">Híbrido</option>
                              <option value="e">Eléctrico</option>
                              
                            </select>
                            <br>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <strong><i class="text-naranja">*</i> Motor volumen </strong>
                        <input type="number" class="form-control"  [(ngModel)]="producto.motorVolumen" name="motorVolumen" #motorVolumen="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <strong><i class="text-naranja">*</i> Año </strong>
                        <input type="number" class="form-control"  [(ngModel)]="producto.anio" name="anio" #anio="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <strong>Transmisión</strong><br>
                        <select class="form-control margen" [(ngModel)]="producto.transmision" name="transmision" #transmision="ngModel">
                            <option [ngValue]="null" disabled>Seleccione transmisión</option>
                            <option value="b">Manual</option>
                            <option value="a">Automática</option>
                        </select>
                        <br>
                    </div>
                    <div class="col-xs-12 col-md-3">
                        <strong>Financiamiento</strong>
                        <select class="form-control" [(ngModel)]="producto.financiamiento" name="financiado" #financiado="ngModel">
                          <option [ngValue]="null" disabled>Seleccione financiamiento</option>
                            <option value="s">SI</option>
                            <option value="n">NO</option>
                       </select>
                       <br>
                    </div>
                    <div class="col-xs-12 col-md-9">
                        <strong>Otros</strong>
                        <input type="text" class="form-control" placeholder="Puede agregar un criterio adicional" [(ngModel)]="producto.otros" name="otros" #otros="ngModel">
                        <br>
                    </div>
                </div>
            </div>
            <div class="box-footer">
                <div class="text-right" >
                    <button type="button" class="btn btn-link" tooltip="cancelar" (click)="goBack()"><span class="svg-noaprobado"></span></button>
                    <button type="button" class="btn btn-link" tooltip="aceptar" (click)="modificarProducto()" ><span class="svg-aprobado"></span></button>
                </div>
            </div>
        </div>
       
            
    </div>
</div>

</section>
