<div class="wrapper">
  <header class="main-header">
      <a class="logo">
          <span class="logo-mini">RM</span>
          <span class="logo-lg"><small>RedMotors </small><b></b></span>
      </a>
      <!-- Header Navbar -->
      <nav class="navbar navbar-fixed-top" role="navigation">
          <!-- Sidebar toggle button-->
          <a class="btn-link sidebar-toggle text-gray" data-toggle="offcanvas" role="button">
              <span class="sr-only">Toggle navigation</span>
          </a>
          <!-- skin -->
          <div class="navbar-custom-menu">
              <ul class="nav navbar-nav">
                  <li data-toggle="tooltip" data-placement="left" title="skin">
                      <a href="#" data-toggle="control-sidebar"><i class="fa fa-wrench"></i></a>
                  </li>
              </ul>
          </div>
      </nav>
  </header>
  <aside class="main-sidebar">
      <section class="sidebar">
          <div class="main-header">
              <a class="logo">
                  <span class="logo-mini" style="padding-left:15px;padding-right:15px;"><img id="imgLogoMini" class="img-responsive" width="50"></span>
                  <span class="logo-lg"><img id="imgLogo" class="img-responsive"></span>
              </a>
          </div>
          <br />
          <div>
            <div class="user-panel" style="white-space:normal;padding-top:80px;padding-bottom:0;" >
                <div class="pull-left image">
                    <img src="assets/images/icono-usuario.png" class="img-responsive">
                </div>
                <div class="pull-left info">
                    <p ></p>
                  
                    <p></p>
                </div>
            </div>
            <br />
                <div >
                    
                    <ul class="sidebar-menu" >
                        <li><a routerLink="/categoria"><i class="fa fa-hand-o-right"></i>Categorías</a></li>
                        <li><a routerLink="/estilo"><i class="fa fa-hand-o-right"></i>Estilos</a></li>
                        <li><a routerLink="/marca"><i class="fa fa-hand-o-right"></i>Marcas</a></li>
                        <li><a routerLink="/modelo"><i class="fa fa-hand-o-right"></i>Modelos</a></li>
                        <li><a routerLink="/moneda"><i class="fa fa-hand-o-right"></i>Monedas</a></li>
                        <li><a routerLink="/financia"><i class="fa fa-hand-o-right"></i>Entidades financieras</a></li>
                        <li><a routerLink="/financiamiento"><i class="fa fa-hand-o-right"></i>Opciones de financiamiento</a></li>
                        <li><a routerLink="/productos"><i class="fa fa-hand-o-right"></i>Vehículos</a></li>
                        <!--<li><a (click)="logout()"><i class="fa fa-fw fa-power-off text-red"></i> Salir</a></li>-->
                        
                    </ul>
                  
                </div>
            </div>
          
      </section>
  </aside>

  <aside class="control-sidebar control-sidebar-dark">
      <div class="tab-content">
          <div class="tab-pane" id="control-sidebar-home-tab">
          </div>
          <div id="control-sidebar-theme-demo-options-tab" class="tab-pane active">
              <!-- panel skins -->
          </div>
      </div>
  </aside>

  <div class="content-wrapper">
      <br><br>
      
    <router-outlet></router-outlet>

  </div>
</div>