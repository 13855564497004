<section class="content-header ng-scope">
    <h1 class="text-navy">
        Opciones de financiamiento
        <small class="fa fa-fw fa-search text-navy"></small>
    </h1>
</section>
<section class="content">
    <div class="box box-rojo" style="margin-bottom:0px;">
        <div class="box-header">
            <h3 class="box-title">Agregar</h3>
        </div>
        <div class="box-body">
            <div class="row">
                <form #frmSolicitud="ngForm">

                    <div class="col-xs-12 col-sm-6 col-lg-4">
                        <strong><i class="text-naranja">*</i> Entidad financiera </strong>
                        <select class="form-control" [(ngModel)]="opcion.codigoEntidadesFinanciamiento" name="codigoEntidadesFinanciamiento" #codigoEntidadesFinanciamiento="ngModel" required >
                            <option [ngValue]="null" selected disabled>Seleccione un tipo</option>
                            <option *ngFor="let entidad of entidades" [ngValue]="entidad.codigo">{{entidad.nombre}}</option>
                        </select>
                        <br>

                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-4">
                        <strong><i class="text-naranja">*</i> Moneda </strong>
                        <select class="form-control" [(ngModel)]="opcion.codigoMonedas" name="codigoMonedas" #codigoMonedas="ngModel" required >
                            <option [ngValue]="null" selected disabled>Seleccione un tipo</option>
                            <option *ngFor="let moneda of monedas " [ngValue]="moneda.codigo">{{moneda.nombre}}</option>
                        </select>
                        <br>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-4" >
                        <strong><i class="text-naranja" >*</i> Nombre </strong>
                        <input type="text" class="form-control" placeholder="nombre" [(ngModel)]="opcion.nombre" name="nombre" #nombre="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-4">
                        <strong><i class="text-naranja">*</i> Usados procentaje prima </strong>
                        <input type="number" class="form-control"  [(ngModel)]="opcion.usadosPorcentajePrima" name="usadosPorcentajePrima" #usadosPorcentajePrima="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-4">
                        <strong><i class="text-naranja">*</i> Usados plazo </strong>
                        <input type="number" class="form-control"  [(ngModel)]="opcion.usadosPlazo" name="usadosPlazo" #usadosPlazo="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-4">
                        <strong><i class="text-naranja">*</i> Usados tasa fija </strong>
                        <input type="number" class="form-control"  [(ngModel)]="opcion.usadosTasaFija" name="usadosTasaFija" #usadosTasaFija="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-4">
                        <strong><i class="text-naranja">*</i> Usados tasa piso </strong>
                        <input type="number" class="form-control"  [(ngModel)]="opcion.usadosTasaPiso" name="usadosTasaPiso" #usadosTasaPiso="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-4">
                        <strong><i class="text-naranja">*</i> Seminuevos porcentaje prima asalariados </strong>
                        <input type="number" class="form-control"  [(ngModel)]="opcion.seminuevosPorcentajePrimaAsalariados" name="seminuevosPorcentajePrimaAsalariados" #seminuevosPorcentajePrimaAsalariados="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-4">
                        <strong><i class="text-naranja">*</i> Seminuevos porcentaje prima independientes </strong>
                        <input type="number" class="form-control"  [(ngModel)]="opcion.seminuevosPorcentajePrimaIndependientes" name="seminuevosPorcentajePrimaIndependientes" #seminuevosPorcentajePrimaIndependientes="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-4">
                        <strong><i class="text-naranja">*</i> Seminuevos plazo </strong>
                        <input type="number" class="form-control"  [(ngModel)]="opcion.seminuevosPlazo" name="seminuevosPlazo" #seminuevosPlazo="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-4">
                        <strong><i class="text-naranja">*</i> Seminuevos tasa fija </strong>
                        <input type="number" class="form-control"  [(ngModel)]="opcion.seminuevosTasaFija" name="seminuevosTasaFija" #seminuevosTasaFija="ngModel" required>
                        <br>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-4">
                        <strong><i class="text-naranja">*</i> Seminuevos tasa piso </strong>
                        <input type="number" class="form-control"  [(ngModel)]="opcion.seminuevosTasaPiso" name="seminuevosTasaPiso" #seminuevosTasaPiso="ngModel" required>
                        <br>
                    </div>
                </form>
            </div>
        </div>
        
        <div class="box-footer">
            <div class="text-right" >
                <button type="button" class="btn btn-link" tooltip="cancelar" (click)="goBack()"><span class="svg-noaprobado"></span></button>
                <button type="button" class="btn btn-link" tooltip="aceptar" (click)="modificarOpcion()" [disabled]="frmSolicitud.invalid" ><span class="svg-aprobado"></span></button>
            </div>
        </div>
    </div>

</section>
