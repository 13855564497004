import { Component, OnInit,TemplateRef } from '@angular/core';
import { CategoriasService } from 'src/app/servicios/categorias.service';
import { ProductosCategoriasService } from 'src/app/servicios/productos-categorias.service';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GuidService } from 'src/app/servicios/guid.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-red-motors-productos-categorias',
  templateUrl: './red-motors-productos-categorias.component.html',
  styleUrls: ['./red-motors-productos-categorias.component.css']
})
export class RedMotorsProductosCategoriasComponent implements OnInit {
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  datos:any;
  categorias:any;
  categoriasP:any;
  modalRef!: BsModalRef;
  categoria={
    codigo:this.guidSvg.newGUID(),
    codigoProductos:this.route.snapshot.params['codigo'],
    codigoCategorias:''

  }

  isCollapsed = false;
  constructor(private categoriaSvg:CategoriasService,
    private productosCategoSvg:ProductosCategoriasService,
    private modalService: BsModalService,
    private guidSvg:GuidService,
    private location:Location,
    private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.listarCategoria();
    this.listar();
  }
  listar():void{
    this._status = 'b'
    this.productosCategoSvg.listarXCodigoProductos(this.route.snapshot.params['codigo'])
    .subscribe(data => {
      this._status = 'c'
      this.categoriasP=data;
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  listarCategoria():void{
    this._status = 'b'
    this.categoriaSvg.listar(null)
    .subscribe(data => {
      this._status = 'c'
      this.categorias=data;
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  irAgregar(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  agregarCategoria(): void{
    this._status = 'b'
    this.productosCategoSvg.agregar(
      this.categoria.codigo,this.categoria.codigoProductos,this.categoria.codigoCategorias)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro éxitoso");
    this.listar();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  eliminarCatego(codigo:string): void{
    this._status = 'b'
    this.productosCategoSvg.eliminar(codigo)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro eliminado con éxito");
      this.listar();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  irEliminar(catego: string,eliminar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(eliminar);
    this.categoria.codigo=catego;
  }
}
