
    <div class="row">
<div class="col-xs-12"style="margin-top:0px">
    <div class="box box-rojo collapsed-box">
        <div class="box-header">
            <h3 class="box-title">Categorías del vehículo</h3>
            <div class="box-tools pull-right">

                <button type="button" class="btn btn-box-tool" tooltip='agregar' (click)="irAgregar(Agregar)">
                    <i class="svg-agregar-general"></i>
                </button>
                <button class="btn btn-box-tool linea-divisoria ng-scope" disabled=""> | </button>
                <button type="button" class="btn btn-box-tool" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
                        <i class="fa fa-minus"></i>
                </button>
            </div>
        </div>
        <div class="box-body" style="padding-bottom: 0px;" [collapse]="isCollapsed">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th class="text-right"></th>
                    </tr>
                </thead>
               
                    <tr *ngFor="let categoriaP of categoriasP">
                        <td>{{categoriaP.categoriasNombre}}</td>
                        <td class="text-right">
                            <div class="btn-group">
                                <button type="button" class="btn btn-link no-padding-sides" tooltip="eliminar"(click)="irEliminar(categoriaP.codigo,eliminar)"><span class="svg-eliminar"></span></button>
                            </div>
                        </td>
                    </tr>

                
            </table>
        </div>
    </div>
</div>
</div>
<ng-template #Agregar>
    <div class="modal-header bg-uniserse">
        <h4 class="modal-title pull-left">Agregar</h4>
        <button type="button" class="close pull-right" aria-label="Close" style="color:#ffffff" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #frmSolicitud="ngForm">
            <div class="col-xs-12">
                <strong><i class="text-naranja">*</i> Categorías </strong>
                <select class="form-control" [(ngModel)]="categoria.codigoCategorias" name="codigoCategorias" #codigoCategorias="ngModel" required >
                    <option [ngValue]="null" selected disabled>Seleccione un tipo</option>
                    <option *ngFor="let categoria of categorias" [ngValue]="categoria.codigo">{{categoria.nombre}}</option>
                </select>
                <br>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <br />
        <button type="button" class="btn btn-link" tooltip="cancelar" (click)="modalRef.hide()"><span class="svg-noaprobado"></span></button>
        <button class="btn btn-link" type="button" tooltip="aceptar" (click)="agregarCategoria();frmSolicitud.resetForm()"[disabled]="frmSolicitud.invalid"><span class="svg-aprobado"></span></button>
    </div>
</ng-template>
<ng-template #eliminar>
    <div class="modal-header bg-uniserse">
        <h4 class="modal-title pull-left">Eliminar</h4>
        <button type="button" class="close pull-right" aria-label="Close" style="color:#ffffff" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ¿Desea eliminar el registro seleccionado?
      </div>
      <div class="modal-footer">
        <div class="col-xs-12">
            <br />
            <button type="button" class="btn btn-link" tooltip="cancelar" (click)="modalRef.hide()"><span class="svg-noaprobado"></span></button>
            <button class="btn btn-link" type="button" tooltip="aceptar" (click)="eliminarCatego(categoria.codigo)"><span class="svg-aprobado"></span></button>
            
        </div>
    </div>
</ng-template>

