import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Archivo } from "../componentes/model/Archivo";

@Injectable({
  providedIn: 'root'
})
export class SubirArchivoService {
  private _url='https://usados-api-2.azurewebsites.net/api/productos/ModificarImagen';
  constructor(private http:HttpClient) { }
  upload(file,codigo):Observable<any> {
  
    // Create form data
    const formData = new FormData(); 
      
    // Store form name as "file" with file data
    formData.append("file",file, file.name);
    formData.append("codigo",codigo);
    // Make http post request over api
    // with formData as req
    return this.http.post(this._url, formData)
}
}
