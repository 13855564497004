import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MarcasService {
  private _url='https://usados-api-2.azurewebsites.net/api/marcas/';
  constructor(private http:HttpClient) { }
  listar(codigo: string | null): Observable<any> {
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo
    })
    return this.http.post<any>(this._url + 'Listar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  agregar(codigo: string, nombre:string): Observable<any> {
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo,
      "nombre": nombre
    })
    return this.http.post<any>(this._url + 'Agregar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  modificar(codigo: string, nombre:string): Observable<any> {
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo,
      "nombre": nombre
    })
    return this.http.post<any>(this._url + 'Modificar', body, {'headers': headers, observe: 'response'}).pipe(catchError(this.errorHandler));
  }
  eliminar(codigo: string): Observable<any> {
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo
    })
    return this.http.post<any>(this._url + 'Eliminar', body, {'headers': headers, observe: 'response'}).pipe(catchError(this.errorHandler));
  }
  errorHandler(error:HttpErrorResponse){
    return throwError(error);
  }
}
