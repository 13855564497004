import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient, HttpResponse, HttpRequest, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { catchError, last, map, tap } from 'rxjs/operators';
import { SubirArchivoService } from 'src/app/servicios/subir-archivo.service';
import { Archivo } from '../model/Archivo';
import { of } from 'rxjs/internal/observable/of';
import { GuidService } from 'src/app/servicios/guid.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-red-motors-subir-img-principal',
  templateUrl: './red-motors-subir-img-principal.component.html',
  styleUrls: ['./red-motors-subir-img-principal.component.css']
})
export class RedMotorsSubirImgPrincipalComponent implements OnInit {
 // Variable to store shortLink from api response
 shortLink: string = "";
 loading: boolean = false; // Flag variable
 file: File = null; // Variable to store file
  constructor(private http: HttpClient, private subirFile:SubirArchivoService, 
    private route:ActivatedRoute,private guidSvg:GuidService,private location:Location) { }
  

  ngOnInit(): void {

  }
  // On file Select
  onChange(event) {
    this.file = event.target.files[0];
}

// OnClick of button Upload
onUpload() {
    this.loading = !this.loading;
    if (this.file.type=="image/jpeg" || this.file.type=="image/jpg"){
    this.subirFile.upload(this.file,this.route.snapshot.params['codigo']).subscribe(
    );
    
    }
    else{
      alert("El archivo no cumple con la extensión valida")
    }
}

}

