import { Component, OnInit,TemplateRef } from '@angular/core';
import { CategoriasService } from 'src/app/servicios/categorias.service';
import { EstilosService } from 'src/app/servicios/estilos.service';
import { MarcasService } from 'src/app/servicios/marcas.service';
import { ModelosService } from 'src/app/servicios/modelos.service';
import { ProductosService } from 'src/app/servicios/productos.service';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Options } from "@angular-slider/ngx-slider";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-red-motors-buscador-productos',
  templateUrl: './red-motors-buscador-productos.component.html',
  styleUrls: ['./red-motors-buscador-productos.component.css']
})
export class RedMotorsBuscadorProductosComponent implements OnInit {
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  isCollapsed = false;
  producto={
    codigo:''
  }
  buscador= {
  codigoCategorias: null,
  codigoMarcas: null,
  codigoModelos: null,
  codigoEstilos: null,
  precioInicial: null,
  precioFinal : null,
  kilometrajeInicial: null,
  kilometrajeFinal: null,
  puertasInicial: null,
  puertasFinal:null,
  combustible: null,
  motorVolumenInicial: null,
  motorVolumenFinal: null,
  anioInicial:null,
  anioFinal: null,
  transmision: null,
  financiamiento: null,
  estado: null,
  criterio:'',
  }
  modalRef!: BsModalRef;
  registros:any;
  pagina!:number;
  cantidadRegistrosPagina!:number;
  conteo!:number;
  paginas: {indice:number, grupo:number}[]=[];
  grupos!:number;
  grupo!:number;
  cantidadPaginasGrupo!:number;
  constructor(
    private productoSvg:ProductosService,
    private modalService: BsModalService,) { }

  ngOnInit(): void {
  
  }
 
  obtenerRegistros(): void{
    this._status = 'b'
    this.productoSvg.buscar(this.buscador.codigoCategorias,this.buscador.codigoMarcas,this.buscador.codigoModelos,this.buscador.codigoEstilos,
      this.buscador.precioInicial,this.buscador.precioFinal,this.buscador.combustible,this.buscador.kilometrajeInicial,this.buscador.kilometrajeFinal,this.buscador.puertasInicial,
      this.buscador.puertasFinal, this.buscador.motorVolumenInicial, this.buscador.motorVolumenFinal, this.buscador.anioInicial, this.buscador.anioFinal,this.buscador.transmision,
      this.buscador.financiamiento,this.buscador.estado,'%' +this.buscador.criterio + '%',this.pagina,this.cantidadRegistrosPagina)
     .subscribe(data =>{
      this._status = 'c'
       this.registros=data;
       this.conteo = 0;
       if (this.registros.length > 0) { this.conteo = parseInt(this.registros[0].conteo) };
       this.calcularPaginas();
     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  //paginacion
 calcularPaginas() {
  var tmp = Math.ceil(this.conteo / this.cantidadRegistrosPagina);
  var tmpArr = new Array();
  tmp = (tmp > 0 ? tmp : 1);
  for (var i = 1, ii = tmp; i <= ii; i++) {
      tmpArr.push({
          indice: i,
          grupo: Math.ceil((i / this.cantidadPaginasGrupo)),
          clase: (i == this.pagina ? 'active' : '')
      });
  }
  this.paginas = tmpArr;
  this.grupos = Object.assign({},this.paginas[this.paginas.length - 1].grupo);
};
 buscar() {
  this.pagina = 1;
  this.cantidadRegistrosPagina = 6;
  this.cantidadPaginasGrupo=5;
  this.conteo = 0;
  this.paginas = [{ indice: 1, grupo: 1 }];
  this.grupos = 1;
  this.cambiarPagina(1);
}
cambiarPagina(pagina:number){
  this.pagina = pagina;
  this.grupo = Math.ceil((this.pagina / this.cantidadPaginasGrupo));
 this.obtenerRegistros(); 
}

retroceder() {
  var tmp = (this.grupo - 1) * this.cantidadPaginasGrupo;
  this.cambiarPagina(tmp);
};
avanzar() {
  var tmp = (this.grupo * this.cantidadPaginasGrupo) + 1;
  this.cambiarPagina(tmp);
};
irEliminar(producto: string,eliminar: TemplateRef<any>) {
  this.modalRef = this.modalService.show(eliminar);
  this.producto.codigo=producto;
}
eliminarProducto(codigo:string): void{
  this._status = 'b'
  this.productoSvg.eliminar(codigo)
   .subscribe(data=> {
    this._status = 'c'
    this.modalRef.hide();
    alert("Registro eliminado con éxito");
    this.buscar();
   },
     error => {
      this._status = 'd'
      this.errorHttp=error;
      alert(error.statusText);
    }); 
}
}
