import { Component, OnInit, TemplateRef } from '@angular/core';
import { GuidService } from 'src/app/servicios/guid.service';
import { ModelosService } from 'src/app/servicios/modelos.service';
import { MarcasService } from 'src/app/servicios/marcas.service';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-red-motors-modelos',
  templateUrl: './red-motors-modelos.component.html',
  styleUrls: ['./red-motors-modelos.component.css']
})
export class RedMotorsModelosComponent implements OnInit {
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  datos:any;
  modelos:any;
  marcas:any;
  modelo={
    codigo:this.guidSvg.newGUID(),
    codigoMarcas:'',
    nombre:'',

  }

  isCollapsed = false;

  modalRef!: BsModalRef;
  selectedOption: any;
  constructor(private guidSvg:GuidService,
    private route:ActivatedRoute,
    private location: Location,
    private modalService: BsModalService,
    private modeloSvg:ModelosService,
    private marcaSvg:MarcasService) { }

  ngOnInit(): void {
    this.listarMarca();
    this.listarModelo();
  }
  listarMarca():void{
    this._status = 'b'
    this.marcaSvg.listar(null)
    .subscribe(data => {
      this._status = 'c'
      this.marcas=data;
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  listarModelo():void{
    this._status = 'b'
    this.modeloSvg.listar(null)
    .subscribe(data => {
      this._status = 'c'
      this.modelos=data;
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  irAgregar(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  agregarModelo(): void{
    this._status = 'b'
    this.modeloSvg.agregar(
      this.modelo.codigo,this.modelo.codigoMarcas,this.modelo.nombre)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro éxitoso");
    this.listarModelo();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  eliminarModelo(codigo:string): void{
    this._status = 'b'
    this.modeloSvg.eliminar(codigo)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro eliminado con éxito");
      this.listarModelo();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  modificarModelo(): void{
    this._status = 'b'
    this.modeloSvg.modificar(
      this.modelo.codigo,this.modelo.codigoMarcas,this.modelo.nombre)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro modificado con éxito");
      this.listarModelo();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  irEliminar(marca: string,eliminar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(eliminar);
    this.modelo.codigo=marca;
  }
  irModificar(marca: any,modificar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modificar);
    this.modelo = Object.assign({}, marca)
  }

}
