import { Component, OnInit, TemplateRef } from '@angular/core';
import { GuidService } from 'src/app/servicios/guid.service';
import { CategoriasService } from 'src/app/servicios/categorias.service';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-red-motors-categorias',
  templateUrl: './red-motors-categorias.component.html',
  styleUrls: ['./red-motors-categorias.component.css']
})
export class RedMotorsCategoriasComponent implements OnInit {
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  datos:any;
  categorias:any;
  categoria={
    codigo:this.guidSvg.newGUID(),
    nombre:'',

  }
  tiposDireccion:any;
  paises:any;
  isCollapsed = false;
  selectedValue!: string;
  modalRef!: BsModalRef;
  selectedOption: any;
  constructor(private guidSvg:GuidService,
    private route:ActivatedRoute,
    private location: Location,
    private modalService: BsModalService,
    private categoriaSvg:CategoriasService) { }

  ngOnInit(): void {
    this.listarCategoria();
  }
  listarCategoria():void{
    this._status = 'b'
    this.categoriaSvg.listar(null)
    .subscribe(data => {
      this._status = 'c'
      this.categorias=data;
      
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
 
  irAgregar(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  agregarCategoria(): void{
    this._status = 'b'
    this.categoriaSvg.agregar(
      this.categoria.codigo,this.categoria.nombre)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro éxitoso");
    this.listarCategoria();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  eliminarCategoria(codigo:string): void{
    this._status = 'b'
    this.categoriaSvg.eliminar(codigo)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro eliminado con éxito");
      this.listarCategoria();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  modificarCategoria(): void{
    this._status = 'b'
    this.categoriaSvg.modificar(
      this.categoria.codigo,this.categoria.nombre)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro modificado con éxito");
      this.listarCategoria();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  irEliminar(categoria: string,eliminar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(eliminar);
    this.categoria.codigo=categoria;
  }
  irModificar(categoria: any,modificar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modificar);
    this.categoria = Object.assign({}, categoria)
  }
 
}
