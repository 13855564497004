import { Component, OnInit, TemplateRef } from '@angular/core';
import { GuidService } from 'src/app/servicios/guid.service';
import { MonedasService } from 'src/app/servicios/monedas.service';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-red-motors-monedas',
  templateUrl: './red-motors-monedas.component.html',
  styleUrls: ['./red-motors-monedas.component.css']
})
export class RedMotorsMonedasComponent implements OnInit {
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  datos:any;
  monedas:any;
  moneda={
    codigo:this.guidSvg.newGUID(),
    nombre:'',
    simbolo:''

  }

  isCollapsed = false;

  modalRef!: BsModalRef;
  selectedOption: any;
  constructor(private guidSvg:GuidService,
    private route:ActivatedRoute,
    private location: Location,
    private modalService: BsModalService,
    private monedaSvg:MonedasService) { }

  ngOnInit(): void {
    this.listarMoneda();
  }
  listarMoneda():void{
    this._status = 'b'
    this.monedaSvg.listar(null)
    .subscribe(data => {
      this._status = 'c'
      this.monedas=data;
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  irAgregar(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  agregarMoneda(): void{
    this._status = 'b'
    this.monedaSvg.agregar(
      this.moneda.codigo,this.moneda.nombre,this.moneda.simbolo)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro éxitoso");
    this.listarMoneda();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  eliminarMoneda(codigo:string): void{
    this._status = 'b'
    this.monedaSvg.eliminar(codigo)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro eliminado con éxito");
      this.listarMoneda();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  modificarMoneda(): void{
    this._status = 'b'
    this.monedaSvg.modificar(
      this.moneda.codigo,this.moneda.nombre,this.moneda.simbolo)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro modificado con éxito");
      this.listarMoneda();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  irEliminar(moneda: string,eliminar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(eliminar);
    this.moneda.codigo=moneda;
  }
  irModificar(moneda: any,modificar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modificar);
    this.moneda = Object.assign({}, moneda)
  }

}
