<section class="content-header ng-scope">

</section>
<section class="content">
<div class="row">
    <div class="col-xs-12">
        <div class="box box-rojo" style="margin-bottom:0px;">
            <div class="box-header">
                <h3 class="box-title">Vehículos</h3>
                <div class="box-tools pull-right">
                    <a routerLink="/agregarProductos" type="button" class="btn btn-link" tooltip="agregar"><span class="svg-agregar-general"></span></a>
                    <button class="btn btn-box-tool linea-divisoria ng-scope"> | </button>
                    <button type="button" class="btn btn-box-tool" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
                        <i class="fa fa-minus"></i>
                    </button>
                </div>
            </div>
            <div class="box-body" style="padding-bottom: 0px;">
                <div class="row">
                    
                    
                    <div class="col-xs-12">
                        <strong>Criterio</strong>
                        <div class="input-group margen">
                            <input type="text" class="form-control" placeholder="Puede agregar un criterio adicional"[(ngModel)]="buscador.criterio" name="criterio" #criterio="ngModel">
                            <span class="input-group-btn">
                                <button class="btn btn-blue btn-flat" type="button" (click)="buscar()"tooltip="buscar"><span class="fa fa-search"></span></button>
                            </span>
                        </div>
                        <br>
                    </div>
                    <div  *ngIf=" _status== 'b'">
                        <p  class="text-center">
                          <img src="../../assets/images/1484.gif">
                        </p>
                      </div>
                    <div class="col-xs-12" *ngIf="_status=='c'">
                        <div  class="">
                            <div class="table-responsive">
                                <table class="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Marca y Modelo</th>
                                            <th>Identificador</th>
                                            <th>Kilometraje</th>
                                            <th>Puertas</th>
                                            <th>Combustible</th>
                                            <th>Motor</th>
                                            <th>Transmisión</th>
                                            <th class="text-right"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let registro of registros">
                                            <td>{{registro.marcasNombre}} {{registro.modelosNombre}} ({{registro.anio}})</td>
                                            <td>{{registro.identificador}}</td>
                                            <td>{{registro.kilometraje}}</td>
                                            <td>{{registro.puertas}}</td>
                                            <td>{{registro.tiposCombustibleNombre}}</td>
                                            <td>{{registro.motorVolumen}}</td>
                                            <td>{{registro.tiposTransmisionNombre}}</td>
                                            <td class="text-right"><div class="btn-group">
                                                <button type="button" class="btn btn-link no-padding-sides" tooltip="eliminar"(click)="irEliminar(registro.codigo,eliminar)"><span class="svg-eliminar"></span></button>
                                                <button routerLink="/detalle/{{registro.codigo}}" type="button" class="btn btn-link no-padding-sides" tooltip="editar"><span class="svg-editar"></span></button>
                                            </div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-xs-12">
                                <div class="pull-right">
                                    <ul class="pagination">
                                        <li *ngIf="grupo > 1"><a style="color: white" class="btn btn-link bg-redMotors" (click)="retroceder()">&laquo;</a></li>
                                        <li *ngFor="let pag of paginas">
                                            <a style="color: white" class="btn btn-link bg-redMotors" (click)="cambiarPagina(pag.indice)">{{pag.indice}}</a>
                                        </li>
                                        <li *ngIf="grupo < grupos"><a style="color: white" class="btn btn-link bg-redMotors" (click)="avanzar()">&raquo;</a></li>
                                    </ul>
                                </div>

                                <br>
                               <span>Página <strong class="text-rojo">{{pagina}}</strong> de <strong class="text-rojo">{{paginas.length}}</strong> para <strong class="text-rojo">{{conteo}}</strong> registro(s)</span>
                            </div>
                        </div>
                    </div>
               
                </div>
            </div>
            
        </div>
       
            
    </div>
    <ng-template #eliminar>
        <div class="modal-header bg-uniserse">
            <h4 class="modal-title pull-left">Eliminar</h4>
            <button type="button" class="close pull-right" aria-label="Close" style="color:#ffffff" (click)="modalRef.hide()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            ¿Desea eliminar el registro seleccionado?
          </div>
          <div class="modal-footer">
            <div class="col-xs-12">
                <br />
                <button type="button" class="btn btn-link" tooltip="cancelar" (click)="modalRef.hide()"><span class="svg-noaprobado"></span></button>
                <button class="btn btn-link" type="button" tooltip="aceptar" (click)="eliminarProducto(producto.codigo)"><span class="svg-aprobado"></span></button>
                
            </div>
        </div>
    </ng-template>
</div>

</section>
