import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalModule, MsalRedirectComponent, MsalInterceptor, MsalGuard } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { RedMotorsCategoriasComponent } from './componentes/red-motors-categorias/red-motors-categorias.component';
import { FormsModule } from '@angular/forms';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { RedMotorsEstilosComponent } from './componentes/red-motors-estilos/red-motors-estilos.component';
import { RedMotorsMarcasComponent } from './componentes/red-motors-marcas/red-motors-marcas.component';
import { RedMotorsModelosComponent } from './componentes/red-motors-modelos/red-motors-modelos.component';
import { RedMotorsMonedasComponent } from './componentes/red-motors-monedas/red-motors-monedas.component';
import { RedMotorsEntidadesFinanciamientoComponent } from './componentes/red-motors-entidades-financiamiento/red-motors-entidades-financiamiento.component';
import { RedMotorsOpcionesFinanciamientoComponent } from './componentes/red-motors-opciones-financiamiento/red-motors-opciones-financiamiento.component';
import { RedMotorsOpcionesFinanciamientoAgregarComponent } from './componentes/red-motors-opciones-financiamiento-agregar/red-motors-opciones-financiamiento-agregar.component';
import { RedMotorsOpcionesFinanciamientoModificarComponent } from './componentes/red-motors-opciones-financiamiento-modificar/red-motors-opciones-financiamiento-modificar.component';
import { RedMotorsBuscadorProductosComponent } from './componentes/red-motors-buscador-productos/red-motors-buscador-productos.component';
import { RedMotorsAgregarProductosComponent } from './componentes/red-motors-agregar-productos/red-motors-agregar-productos.component';
import { RedMotorsModificarProductosComponent } from './componentes/red-motors-modificar-productos/red-motors-modificar-productos.component';
import { RedMotorsDetalleProductosComponent } from './componentes/red-motors-detalle-productos/red-motors-detalle-productos.component';
import { RedMotorsProductosCategoriasComponent } from './componentes/red-motors-productos-categorias/red-motors-productos-categorias.component';
import { RedMotorsSubirImgPrincipalComponent } from './componentes/red-motors-subir-img-principal/red-motors-subir-img-principal.component';
import { RedMotorsGaleriaImagenesComponent } from './componentes/red-motors-galeria-imagenes/red-motors-galeria-imagenes.component';


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    RedMotorsCategoriasComponent,
    RedMotorsEstilosComponent,
    RedMotorsMarcasComponent,
    RedMotorsModelosComponent,
    RedMotorsMonedasComponent,
    RedMotorsEntidadesFinanciamientoComponent,
    RedMotorsOpcionesFinanciamientoComponent,
    RedMotorsOpcionesFinanciamientoAgregarComponent,
    RedMotorsOpcionesFinanciamientoModificarComponent,
    RedMotorsBuscadorProductosComponent,
    RedMotorsAgregarProductosComponent,
    RedMotorsModificarProductosComponent,
    RedMotorsDetalleProductosComponent,
    RedMotorsProductosCategoriasComponent,
    RedMotorsSubirImgPrincipalComponent,
    RedMotorsGaleriaImagenesComponent,
  
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: 'ac78713c-070d-41ae-8e42-6b801dd627e3',
          authority: 'https://login.microsoftonline.com/f1907c60-1b08-47ac-8a61-8c51bd5b8d95', 
          redirectUri: 'https://adminusados.redmotorscr.com/',
          postLogoutRedirectUri: 'https://adminusados.redmotorscr.com/'
         //redirectUri: 'http://localhost:4200/',
         // postLogoutRedirectUri: 'http://localhost:4200/'
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        }
      }),
      {
        interactionType: InteractionType.Redirect, // Msal Guard Configuration
        authRequest: {
          scopes: ['user.read']
        }
      }, 
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([ 
         // ['https://graph.microsoft.com/v1.0/me', ['user.read']],
          ['https://usados-api-2.azurewebsites.net/api/', ['api://c1294a78-9ff2-4bca-bd80-1910d579c170/general']]  
        ])
      }
    ),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    FormsModule,
    NgxSliderModule,
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot()
    
  ],
  providers: [
    MsalGuard,
    {
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		}
  ],
  bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
