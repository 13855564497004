<div ng-show="mantenimientoPersonasContenido === 'b'" class="ng-scope">
    <section class="content-header">
        <h1 class="text-navy">
            Datos del vehiculo
        </h1>
        
    </section>
    <section class="content">
        <div class="row">
            <div class="col-md-4">
                <div class="box box-rojo" >
                    <div class="box-body box-profile">
                        <div class="row">
                        <div class="col-xs-12 col-md-12 col-lg-4 container">
                        <img  width="135px" [src]="producto.imagen" alt="foto">
                         <button type="button" class="btn btn-box-tool ng-scope" tooltip="Editar" (click)="irModificar(modificarImagen)">
                            <i class="svg-editar"></i>
                        </button> 
                       
                        </div>
                        <div class="col-xs-12 col-md-12 col-lg-6 text-center">
                        <h3 class="profile-username ng-binding"> {{producto.marcasNombre}} {{producto.modelosNombre}} ({{producto.anio}})<br>
                            {{producto.monedasSimbolo}}{{producto.precio | number}}
                        </h3>
                        
                    </div> 
                        <div class="col-xs-12 col-md-12 col-lg-2">
                            <button type="button" class="btn btn-box-tool ng-scope" tooltip="Editar" routerLink='/modificarProductos/{{producto.identificador}}'>
                                <i class="svg-editar"></i>
                            </button>
                      
                        
                    </div> 
                </div>
                <br>
                        <ul class="list-group list-group-unbordered" >
                            <li class="list-group-item">
                                <b>Identificador</b><span class="pull-right ng-scope">{{producto.identificador}}</span>
                            </li>
                            <li class="list-group-item">
                                <b>Precio sugerido</b><span class="pull-right ng-scope">{{producto.precioSugerido}}</span>
                            </li>
                             <li class="list-group-item">
                                <b>kilometraje</b><span class="pull-right ng-scope">{{producto.kilometraje}}</span>
                            </li>
                            <li class="list-group-item">
                                <b>Puertas</b><span class="pull-right ng-scope">{{producto.puertas}}</span>
                            </li>
                            <li class="list-group-item">
                                <b>Combustible</b><span class="pull-right ng-scope">{{producto.tiposCombustibleNombre}}</span>
                            </li>
                            <li class="list-group-item">
                                <b>Motor</b><span class="pull-right ng-scope">{{producto.motorVolumen}}</span>
                            </li>
                            <li class="list-group-item">
                                <b>Trasmisión</b> <span class="pull-right ng-binding">{{producto.tiposTransmisionNombre}}</span>
                            </li>
                            <li class="list-group-item">
                                <b>Marca</b> <span class="pull-right ng-binding">{{producto.marcasNombre}}</span>
                            </li>
                            <li class="list-group-item">
                                <b>Finaciamiento</b> <span class="pull-right ng-binding">{{producto.tiposFinanciamientoNombre}}</span>
                            </li>
                            <li class="list-group-item">
                                <b>Otros</b> <span class="pull-right ng-binding">{{producto.otros}}</span>
                            </li>
                        </ul>
                        
                        
                    </div>
                </div>

            </div>
            <div class="col-md-8">
              
                <app-red-motors-productos-categorias></app-red-motors-productos-categorias>
                <app-red-motors-galeria-imagenes></app-red-motors-galeria-imagenes>
            </div>
        
        </div>
        <ng-template #modificarImagen>
            <div class="modal-header bg-uniserse">
                <h4 class="modal-title pull-left">Modificar</h4>
                <button type="button" class="close pull-right" aria-label="Close" style="color:#ffffff" (click)="modalRef.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form #frmModificar="ngForm">
                    <div class="text-center">
                        <p>Únicamente se aceptan archivos con las siguientes extensiones .jpg || .jpeg</p>
                        <input class="form-control" type="file" 
                                (change)="onChange($event)">
                      
                       
                    </div>
                      
                    <!--Flag variable is used here-->
                    
                </form>
              </div>
              <div class="modal-footer">
                <div class="col-xs-12">
                    <br />
                    <button type="button" class="btn btn-link" tooltip="cancelar" (click)="modalRef.hide()"><span class="svg-noaprobado"></span></button>
                    <button (click)="onUpload()" tooltip="subir archivo" class="btn btn-link"><i class="svg-descarga"></i></button>
                    
                </div>
            </div>
        </ng-template>
    </section>
</div>
<br>
<br>
<br>

