import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders,HttpErrorResponse } from "@angular/common/http";
import { Observable,throwError } from "rxjs";
import { Archivo } from "../componentes/model/Archivo";
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class GaleriaImagenesService {
  private _url='https://usados-api-2.azurewebsites.net/api/imagenes/';
  constructor(private http:HttpClient) { }
  upload(codigo,codigoProductos,file):Observable<any> {
  
    // Create form data
    const formData = new FormData(); 
      
    // Store form name as "file" with file data
    formData.append("codigo",codigo);
    formData.append("codigoProductos",codigoProductos);
    formData.append("file",file, file.name);
    
    // Make http post request over api
    // with formData as req
    return this.http.post(this._url +'Agregar', formData)
}
listar(codigo: string | null): Observable<any> {
  const headers = {'content-type': 'application/json'}
  const body = JSON.stringify({
    "codigo": codigo
  })
  return this.http.post<any>(this._url + 'Listar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
}
listarXCodigoProductos(codigoProductos: string | null): Observable<any> {
  const headers = {'content-type': 'application/json'}
  const body = JSON.stringify({
    "codigoProductos": codigoProductos
  })
  return this.http.post<any>(this._url + 'ListarXCodigoProductos', body, {'headers': headers}).pipe(catchError(this.errorHandler));
}
eliminar(codigo: string | null): Observable<any> {
  const headers = {'content-type': 'application/json'}
  const body = JSON.stringify({
    "codigo": codigo
  })
  return this.http.post<any>(this._url + 'Eliminar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
}
errorHandler(error:HttpErrorResponse){
  return throwError(error);
}
}
