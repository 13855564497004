import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OpcionesFinanciamientoService {
  private _url='https://usados-api-2.azurewebsites.net/api/opcionesFinanciamiento/';
  constructor(private http:HttpClient) { }
  listar(codigo: string | null): Observable<any> {
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo
    })
    return this.http.post<any>(this._url + 'Listar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  agregar(codigo: string, codigoEntidadesFinanciamiento:string,codigoMonedas:string,nombre:string,usadosPorcentajePrima:number,
    usadosPlazo:number,usadosTasaFija:number,usadosTasaPiso:number,seminuevosPorcentajePrimaAsalariados:number,seminuevosPorcentajePrimaIndependientes:number,
    seminuevosPlazo:number,seminuevosTasaFija:number,seminuevosTasaPiso:number): Observable<any> {
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo,
      "codigoEntidadesFinanciamiento":codigoEntidadesFinanciamiento,
      "codigoMonedas":codigoMonedas,
      "nombre": nombre,
      "usadosPorcentajePrima":usadosPorcentajePrima,
      "usadosPlazo":usadosPlazo,
      "usadosTasaFija":usadosTasaFija,
      "usadosTasaPiso":usadosTasaPiso,
      "seminuevosPorcentajePrimaAsalariados":seminuevosPorcentajePrimaAsalariados,
      "seminuevosPorcentajePrimaIndependientes":seminuevosPorcentajePrimaIndependientes,
      "seminuevosPlazo":seminuevosPlazo,
      "seminuevosTasaFija":seminuevosTasaFija,
      "seminuevosTasaPiso":seminuevosTasaPiso
    })
    return this.http.post<any>(this._url + 'Agregar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  modificar(codigo: string, codigoEntidadesFinanciamiento:string,codigoMonedas:string,nombre:string,usadosPorcentajePrima:number,
    usadosPlazo:number,usadosTasaFija:number,usadosTasaPiso:number,seminuevosPorcentajePrimaAsalariados:number,seminuevosPorcentajePrimaIndependientes:number,
    seminuevosPlazo:number,seminuevosTasaFija:number,seminuevosTasaPiso:number): Observable<any> {
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo,
      "codigoEntidadesFinanciamiento":codigoEntidadesFinanciamiento,
      "codigoMonedas":codigoMonedas,
      "nombre": nombre,
      "usadosPorcentajePrima":usadosPorcentajePrima,
      "usadosPlazo":usadosPlazo,
      "usadosTasaFija":usadosTasaFija,
      "usadosTasaPiso":usadosTasaPiso,
      "seminuevosPorcentajePrimaAsalariados":seminuevosPorcentajePrimaAsalariados,
      "seminuevosPorcentajePrimaIndependientes":seminuevosPorcentajePrimaIndependientes,
      "seminuevosPlazo":seminuevosPlazo,
      "seminuevosTasaFija":seminuevosTasaFija,
      "seminuevosTasaPiso":seminuevosTasaPiso
    })
    return this.http.post<any>(this._url + 'Modificar', body, {'headers': headers, observe: 'response'}).pipe(catchError(this.errorHandler));
  }
  eliminar(codigo: string): Observable<any> {
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo
    })
    return this.http.post<any>(this._url + 'Eliminar', body, {'headers': headers, observe: 'response'}).pipe(catchError(this.errorHandler));
  }
  errorHandler(error:HttpErrorResponse){
    return throwError(error);
  }
}
