import { Component, OnInit, TemplateRef } from '@angular/core';
import { GuidService } from 'src/app/servicios/guid.service';
import { EstilosService } from 'src/app/servicios/estilos.service';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-red-motors-estilos',
  templateUrl: './red-motors-estilos.component.html',
  styleUrls: ['./red-motors-estilos.component.css']
})
export class RedMotorsEstilosComponent implements OnInit {
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  datos:any;
  estilos:any;
  estilo={
    codigo:this.guidSvg.newGUID(),
    nombre:'',

  }
  tiposDireccion:any;
  paises:any;
  isCollapsed = false;
  selectedValue!: string;
  modalRef!: BsModalRef;
  selectedOption: any;
  constructor(private guidSvg:GuidService,
    private route:ActivatedRoute,
    private location: Location,
    private modalService: BsModalService,
    private estiloSvg:EstilosService) { }

  ngOnInit(): void {
    this.listarEstilo();
  }
  listarEstilo():void{
    this._status = 'b'
    this.estiloSvg.listar(null)
    .subscribe(data => {
      this._status = 'c'
      this.estilos=data;
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
 
  irAgregar(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  agregarEstilo(): void{
    this._status = 'b'
    this.estiloSvg.agregar(
      this.estilo.codigo,this.estilo.nombre)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro éxitoso");
    this.listarEstilo();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  eliminarEstilo(codigo:string): void{
    this._status = 'b'
    this.estiloSvg.eliminar(codigo)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro eliminado con éxito");
      this.listarEstilo();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  modificarEstilo(): void{
    this._status = 'b'
    this.estiloSvg.modificar(
      this.estilo.codigo,this.estilo.nombre)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro modificado con éxito");
      this.listarEstilo();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  irEliminar(estilo: string,eliminar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(eliminar);
    this.estilo.codigo=estilo;
  }
  irModificar(estilo: any,modificar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modificar);
    this.estilo = Object.assign({}, estilo)
  }

}
