import { Component, OnInit, TemplateRef } from '@angular/core';
import { GuidService } from 'src/app/servicios/guid.service';
import { EntidadesFinanciamientoService } from 'src/app/servicios/entidades-financiamiento.service';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-red-motors-entidades-financiamiento',
  templateUrl: './red-motors-entidades-financiamiento.component.html',
  styleUrls: ['./red-motors-entidades-financiamiento.component.css']
})
export class RedMotorsEntidadesFinanciamientoComponent implements OnInit {
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  datos:any;
  financiamientos:any;
  financiamiento={
    codigo:this.guidSvg.newGUID(),
    nombre:'',

  }
  isCollapsed = false;
  selectedValue!: string;
  modalRef!: BsModalRef;
  selectedOption: any;
  constructor(private guidSvg:GuidService,
    private route:ActivatedRoute,
    private location: Location,
    private modalService: BsModalService,
    private financiamientoSvg:EntidadesFinanciamientoService) { }

  ngOnInit(): void {
    this.listarFinanciamiento();
  }
  listarFinanciamiento():void{
    this._status = 'b'
    this.financiamientoSvg.listar(null)
    .subscribe(data => {
      this._status = 'c'
      this.financiamientos=data;
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
 
  irAgregar(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  agregarFinanciamiento(): void{
    this._status = 'b'
    this.financiamientoSvg.agregar(
      this.financiamiento.codigo,this.financiamiento.nombre)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro éxitoso");
    this.listarFinanciamiento();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  eliminarFinanciamiento(codigo:string): void{
    this._status = 'b'
    this.financiamientoSvg.eliminar(codigo)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro eliminado con éxito");
      this.listarFinanciamiento();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  modificarFinanciamiento(): void{
    this._status = 'b'
    this.financiamientoSvg.modificar(
      this.financiamiento.codigo,this.financiamiento.nombre)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro modificado con éxito");
      this.listarFinanciamiento();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  irEliminar(financiamiento: string,eliminar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(eliminar);
    this.financiamiento.codigo=financiamiento;
  }
  irModificar(financiamiento: any,modificar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modificar);
    this.financiamiento = Object.assign({}, financiamiento)
  }


}
