import { Component, OnInit,TemplateRef } from '@angular/core';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { OpcionesFinanciamientoService } from 'src/app/servicios/opciones-financiamiento.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-red-motors-opciones-financiamiento',
  templateUrl: './red-motors-opciones-financiamiento.component.html',
  styleUrls: ['./red-motors-opciones-financiamiento.component.css']
})
export class RedMotorsOpcionesFinanciamientoComponent implements OnInit {
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  opciones:any;
  modalRef!: BsModalRef;
  opcion={
    codigo:'',


  }
  isCollapsed = false;
  constructor(private opcionesSvg:OpcionesFinanciamientoService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.listarOpciones();
  }
  listarOpciones():void{
    this._status = 'b'
    this.opcionesSvg.listar(null)
    .subscribe(data => {
      this._status = 'c'
      this.opciones=data;
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  eliminarOpcion(codigo:string): void{
    this._status = 'b'
    this.opcionesSvg.eliminar(codigo)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro eliminado con éxito");
      this.listarOpciones();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  irEliminar(opcion: string,eliminar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(eliminar);
    this.opcion.codigo=opcion;
  }
}
