<section class="content-header ng-scope">
   
</section>
<section class="content">
    <div class="row">
        <div class="col-xs-12"style="margin-top:0px">
    <div class="box box-rojo collapsed-box">
        <div class="box-header">
            <h3 class="box-title">Opciones de financiamiento</h3>
            <div class="box-tools pull-right">

                <button type="button" class="btn btn-box-tool" tooltip='agregar'routerLink="/agregarFinanciamiento" >
                    <i class="svg-agregar-general"></i>
                </button>
                <button class="btn btn-box-tool linea-divisoria ng-scope" disabled=""> | </button>
                <button type="button" class="btn btn-box-tool" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
                        <i class="fa fa-minus"></i>
                </button>
            </div>
        </div>
        <div class="box-body" style="padding-bottom: 0px;" [collapse]="isCollapsed">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Nombre</th>
                       <th>Seminuevos</th> 
                       <th>Usados</th>
                        <th class="text-right"></th>
                    </tr>
                </thead>
               
                    <tr *ngFor="let opcion of opciones">
                        <td>{{opcion.nombre}}</td>
                        <td><strong>seminuevos plazo:</strong>{{opcion.seminuevosPlazo}}<br>
                            <strong>seminuevos porcentaje prima asalariados:</strong> {{opcion.seminuevosPorcentajePrimaAsalariados}}<br>
                            <strong>seminuevos porcentaje prima independientes:</strong> {{opcion.seminuevosPorcentajePrimaIndependientes}}<br>
                            <strong>seminuevos tasa fija:</strong> {{opcion.seminuevosTasaFija}}<br>
                            <strong>seminuevos tasa piso:</strong>{{opcion.seminuevosTasaPiso}}<br><br></td>
                        <td><strong>usados plazo:</strong>{{opcion.usadosPlazo}}<br>
                            <strong>usados porcentaje prima:</strong> {{opcion.usadosPorcentajePrima}}<br>
                            <strong>usados tasa fija:</strong> {{opcion.usadosTasaFija}}<br>
                            <strong>usados tasa Piso:</strong> {{opcion.usadosTasaPiso}}<br><br></td>
                        <td class="text-right">
                            <div class="btn-group">
                                <button type="button" class="btn btn-link no-padding-sides" tooltip="eliminar"(click)="irEliminar(opcion.codigo,eliminar)"><span class="svg-eliminar"></span></button>
                                <button type="button" class="btn btn-link no-padding-sides" tooltip="editar" routerLink="/modificarFinanciamiento/{{opcion.codigo}}"><span class="svg-editar"></span></button>
                            </div>
                        </td>
                    </tr>

                
            </table>
        </div>
    </div>
</div>
</div>


<ng-template #eliminar>
    <div class="modal-header bg-uniserse">
        <h4 class="modal-title pull-left">Eliminar</h4>
        <button type="button" class="close pull-right" aria-label="Close" style="color:#ffffff" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ¿Desea eliminar el registro seleccionado?
      </div>
      <div class="modal-footer">
        <div class="col-xs-12">
            <br />
            <button type="button" class="btn btn-link" tooltip="cancelar" (click)="modalRef.hide()"><span class="svg-noaprobado"></span></button>
            <button class="btn btn-link" type="button" tooltip="aceptar" (click)="eliminarOpcion(opcion.codigo)"><span class="svg-aprobado"></span></button>
            
        </div>
    </div>
</ng-template>

</section>
