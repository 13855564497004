import { Component, OnInit, Input, Output, EventEmitter,TemplateRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { CategoriasService } from 'src/app/servicios/categorias.service';
import { GaleriaImagenesService } from 'src/app/servicios/galeria-imagenes.service';
import { HttpClient,HttpErrorResponse, HttpResponse,HttpEventType } from '@angular/common/http';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GuidService } from 'src/app/servicios/guid.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-red-motors-galeria-imagenes',
  templateUrl: './red-motors-galeria-imagenes.component.html',
  styleUrls: ['./red-motors-galeria-imagenes.component.css']
})
export class RedMotorsGaleriaImagenesComponent implements OnInit {
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  datos:any;
  categorias:any;
  categoriasP:any;
  modalRef!: BsModalRef;
  galeria={
    codigo:'',
    codigoProductos:this.route.snapshot.params['codigo']
  }
  dynamic!: number;
  type!: 'success' | 'info' | 'warning' | 'danger';
  shortLink: string = "";
  loading: boolean = false; // Flag variable
  file: File = null; // Variable to store file
  galerias:any;
  galeriasP:any;
  isCollapsed = false;
  progress:any;
  prueba:any;
  constructor(private categoriaSvg:CategoriasService,
    private galeriaSvg:GaleriaImagenesService,
    private modalService: BsModalService,
    private guidSvg:GuidService,
    private location:Location,
    private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.listarImagen();
    this.listar();
  }
  listar():void{
    this._status = 'b'
    this.galeriaSvg.listarXCodigoProductos(this.route.snapshot.params['codigo'])
    .subscribe(data => {
      this._status = 'c'
      this.galeriasP=data;
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  listarImagen():void{
    this._status = 'b'
    this.galeriaSvg.listar(null)
    .subscribe(data => {
      this._status = 'c'
      this.galerias=data;
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  irAgregar(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  
  eliminarGaleria(codigo:string): void{
    this._status = 'b'
    this.galeriaSvg.eliminar(codigo)
     .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      alert("Registro eliminado con éxito");
      this.listar();
     },
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  irEliminar(galeria: string,eliminar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(eliminar);
    this.galeria.codigo=galeria;
  }
  // On file Select
  onChange(event) {
    this.file = event.target.files[0];
}

// OnClick of button Upload
onUpload() {
    this.loading = true;
    if (this.file.type=="image/jpeg" || this.file.type=="image/jpg"){
    this.galeriaSvg.upload(this.guidSvg.newGUID(),this.galeria.codigoProductos,this.file)
    
    .subscribe(data=> {
      /*if (this.prueba == HttpEventType.UploadProgress) {
        const total: number = this.prueba; 
        
      this.progress = Math.round(100 * (data.loaded / total));
      if (this.progress < 25) {
        this.type = 'danger';
      } else if (this.progress < 50) {
        this.type = 'warning';
      } else if (this.progress < 75) {
        this.type = 'info';
      }
       else {
        this.type = 'success';
      }
   
      this.dynamic = this.progress;*/
     
      this._status = 'c'
     
      this.modalRef.hide();
      this.listar();
    
    },
  
       error => {
        this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      }); 
    }
  else{
    alert("La extensión del archivo no es valida");
  }
  }
}
