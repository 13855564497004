import { Component, OnInit } from '@angular/core';
import { CategoriasService } from 'src/app/servicios/categorias.service';
import { EstilosService } from 'src/app/servicios/estilos.service';
import { MarcasService } from 'src/app/servicios/marcas.service';
import { ModelosService } from 'src/app/servicios/modelos.service';
import { ProductosService } from 'src/app/servicios/productos.service';
import { GuidService } from 'src/app/servicios/guid.service';
import { MonedasService } from 'src/app/servicios/monedas.service';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-red-motors-modificar-productos',
  templateUrl: './red-motors-modificar-productos.component.html',
  styleUrls: ['./red-motors-modificar-productos.component.css']
})
export class RedMotorsModificarProductosComponent implements OnInit {
  modelos;any;
  estilos:any;
  monedas:any;
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  isCollapsed = false;
  producto:any= {}
  datos:any;
  constructor(private categoriaSvg:CategoriasService,
    private estiloSvg:EstilosService,
    private marcaSvg:MarcasService,
    private modeloSvg:ModelosService,
    private productoSvg:ProductosService,
    private guidSvg:GuidService,
    private monedaSvg:MonedasService,
    private location:Location,
    private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.productoListar();
    this.modeloListar();
    this.modeloListar();
    this.estilosListar();
    this.monedaListar();
  }
  productoListar():void{
    this._status = 'b'
    this.productoSvg.listar(this.route.snapshot.params['codigo'])
    .subscribe(data => {
      this._status = 'c'
      this.datos=data;
      this.producto=Object.assign({}, this.datos[0])

     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  goBack(): void {
    this.location.back();
  }
  monedaListar():void{
    this._status = 'b'
    this.monedaSvg.listar(null)
    .subscribe(data => {
      this._status = 'c'
      this.monedas=data;
      
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  estilosListar(): void{
    this._status = 'b'
    this.estiloSvg.listar(null)
     .subscribe(data =>{
      
       this.estilos=data
       this._status = 'c'

     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
 
  modeloListar(): void{
    this._status = 'b'
    this.modeloSvg.listar(null)
     .subscribe(data =>{
      
       this.modelos=data
       this._status = 'c'

     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  modificarProducto():void{
    this._status='b'
    this.productoSvg.modificar(this.producto.codigo,this.producto.codigoModelos,this.producto.codigoEstilos,this.producto.codigoMonedas,
      this.producto.identificador,this.producto.precio,this.producto.precioSugerido,this.producto.kilometraje,
      this.producto.puertas,this.producto.combustible,this.producto.motorVolumen,this.producto.anio,this.producto.transmision,
      this.producto.financiamiento,this.producto.otros,this.producto.estado)
      .subscribe(data=> {
        this._status = 'c'
        alert("Registro modificado con éxito");
        this.goBack();
       },
         error => {
          this._status = 'd'
          this.errorHttp=error;
          alert(error.statusText);
        }); 
  }
}
