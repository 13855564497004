import { Component, OnInit } from '@angular/core';
import { CategoriasService } from 'src/app/servicios/categorias.service';
import { EstilosService } from 'src/app/servicios/estilos.service';
import { MarcasService } from 'src/app/servicios/marcas.service';
import { ModelosService } from 'src/app/servicios/modelos.service';
import { ProductosService } from 'src/app/servicios/productos.service';
import { GuidService } from 'src/app/servicios/guid.service';
import { MonedasService } from 'src/app/servicios/monedas.service';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';

@Component({
  selector: 'app-red-motors-agregar-productos',
  templateUrl: './red-motors-agregar-productos.component.html',
  styleUrls: ['./red-motors-agregar-productos.component.css']
})
export class RedMotorsAgregarProductosComponent implements OnInit {
modelos;any;
estilos:any;
monedas:any;
_status: string = 'a'; //a: initial, b: working, c: ok, d: error
errorHttp!: HttpErrorResponse;
isCollapsed = false;
producto= {
codigo:this.guidSvg.newGUID() ,
codigoModelos: '',
codigoEstilos: '',
codigoMonedas:'',
identificador:'',
precio: 0,
precioSugerido : 0,
kilometraje:0,
puertas:0,
combustible: '',
motorVolumen: 0,
anio:0,
transmision: '',
financiamiento: '',
otros:'',
estado: 'a',
}
  constructor(private categoriaSvg:CategoriasService,
    private estiloSvg:EstilosService,
    private marcaSvg:MarcasService,
    private modeloSvg:ModelosService,
    private productoSvg:ProductosService,
    private guidSvg:GuidService,
    private monedaSvg:MonedasService,
    private location:Location) { }

  ngOnInit(): void {
    this.estilosListar();
    this.modeloListar();
    this.monedaListar();
  }
  monedaListar():void{
    this._status = 'b'
    this.monedaSvg.listar(null)
    .subscribe(data => {
      this._status = 'c'
      this.monedas=data;
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  estilosListar(): void{
    this._status = 'b'
    this.estiloSvg.listar(null)
     .subscribe(data =>{
      
       this.estilos=data
       this._status = 'c'

     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
 
  modeloListar(): void{
    this._status = 'b'
    this.modeloSvg.listar(null)
     .subscribe(data =>{
      
       this.modelos=data
       this._status = 'c'

     },
       error => {
        this.errorHttp=error;
        alert(error.statusText);
      }); 
  }
  agregarProducto():void{
    this._status='b'
    this.productoSvg.agregar(this.producto.codigo,this.producto.codigoModelos,this.producto.codigoEstilos,this.producto.codigoMonedas,
      this.producto.identificador,this.producto.precio,this.producto.precioSugerido,this.producto.kilometraje,
      this.producto.puertas,this.producto.combustible,this.producto.motorVolumen,this.producto.anio,this.producto.transmision,
      this.producto.financiamiento,this.producto.otros,this.producto.estado)
      .subscribe(data=> {
        this._status = 'c'
        alert("Registro éxitoso");
        this.goBack();
       },
         error => {
          this._status = 'd'
          this.errorHttp=error;
          alert(error.statusText);
        }); 
  }
  goBack(): void {
    this.location.back();
  }
}
