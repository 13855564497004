import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {
  private _url='https://usados-api-2.azurewebsites.net/api/productos/';
  constructor(private http:HttpClient) { }
  buscar(codigoCategorias: string | null, codigoMarcas: string | null, codigoModelos: string | null, codigoEstilos: string | null, precioInicial: number | null, 
    precioFinal:number | null, kilometrajeInicial:number | null, kilometrajeFinal:number | null , puertasInicial: number | null, puertasFinal:null,
    combustible: string | null, motorVolumenInicial:number | null, motorVolumenFinal: number | null, anioInicial: number | null, anioFinal: number | null,
    transmision: string | null, financiamiento: string | null, estado: string | null, criterio:string | null, pagina:number,cantidadRegistrosPagina:number
    ): Observable<any> {
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigoCategorias": codigoCategorias,
      "codigoMarcas": codigoMarcas,
      "codigoModelos": codigoModelos,
      "codigoEstilos": codigoEstilos,
      "precioInicial": precioInicial,
      "precioFinal": precioFinal,
      "kilometrajeInicial": kilometrajeInicial,
      "kilometrajeFinal": kilometrajeFinal,
      "puertasInicial": puertasInicial,
      "puertasFinal": puertasFinal,
      "combustible": combustible,
      "motorVolumenInicial":motorVolumenInicial,
      "motorVolumenFinal": motorVolumenFinal,
      "anioInicial": anioInicial,
      "anioFinal": anioFinal,
      "transmision": transmision,
      "financiamiento": financiamiento,
      "estado":estado,
      "criterio":criterio,
      "pagina":pagina,
      "cantidadRegistrosPagina":cantidadRegistrosPagina
    })
    return this.http.post<any>(this._url + 'Buscar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  listar(codigo: string | null): Observable<any> {
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo
    })
    return this.http.post<any>(this._url + 'Listar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  agregar(codigo: string ,  codigoModelos: string | null, codigoEstilos: string | null, codigoMonedas: string |null, identificador: string | null, 
    precio:number | null,precioSugerido:number | null, kilometraje:number | null , puertas: number | null, 
    combustible: string | null, motorVolumen:number | null, anio: number | null, 
    transmision: string | null, financiamiento: string | null,otros:string | null, estado: string | null): Observable<any> {
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo,
      "codigoModelos":codigoModelos,
      "codigoEstilos":codigoEstilos,
      "codigoMonedas":codigoMonedas,
      "identificador":identificador,
      "precio":precio,
      "precioSugerido":precioSugerido,
      "kilometraje":kilometraje,
      "puertas":puertas,
      "combustible":combustible,
      "motorVolumen":motorVolumen,
      "anio":anio,
      "transmision":transmision,
      "financiamiento":financiamiento,
      "otros":otros,
      "estado":estado
    })
    return this.http.post<any>(this._url + 'Agregar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  modificar(codigo: string ,  codigoModelos: string | null, codigoEstilos: string | null, codigoMonedas: string |null, identificador: string | null, 
    precio:number | null,precioSugerido:number | null, kilometraje:number | null , puertas: number | null, 
    combustible: string | null, motorVolumen:number | null, anio: number | null, 
    transmision: string | null, financiamiento: string | null,otros:string | null, estado: string | null): Observable<any> {
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo,
      "codigoModelos":codigoModelos,
      "codigoEstilos":codigoEstilos,
      "codigoMonedas":codigoMonedas,
      "identificador":identificador,
      "precio":precio,
      "precioSugerido":precioSugerido,
      "kilometraje":kilometraje,
      "puertas":puertas,
      "combustible":combustible,
      "motorVolumen":motorVolumen,
      "anio":anio,
      "transmision":transmision,
      "financiamiento":financiamiento,
      "otros":otros,
      "estado":estado
    })
    return this.http.post<any>(this._url + 'Modificar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  eliminar(codigo: string | null): Observable<any> {
    const headers = {'content-type': 'application/json'}
    const body = JSON.stringify({
      "codigo": codigo
    })
    return this.http.post<any>(this._url + 'Eliminar', body, {'headers': headers}).pipe(catchError(this.errorHandler));
  }
  errorHandler(error:HttpErrorResponse){
    return throwError(error);
  }
  
}
