import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AppComponent } from './app.component';
import { RedMotorsHomeComponent } from './componentes/red-motors-home/red-motors-home.component';
import { RedMotorsCategoriasComponent } from './componentes/red-motors-categorias/red-motors-categorias.component';
import { RedMotorsEstilosComponent } from './componentes/red-motors-estilos/red-motors-estilos.component';
import { RedMotorsMarcasComponent } from './componentes/red-motors-marcas/red-motors-marcas.component';
import { RedMotorsModelosComponent } from './componentes/red-motors-modelos/red-motors-modelos.component';
import { RedMotorsMonedasComponent } from './componentes/red-motors-monedas/red-motors-monedas.component';
import { RedMotorsEntidadesFinanciamientoComponent } from './componentes/red-motors-entidades-financiamiento/red-motors-entidades-financiamiento.component';
import { RedMotorsOpcionesFinanciamientoAgregarComponent } from './componentes/red-motors-opciones-financiamiento-agregar/red-motors-opciones-financiamiento-agregar.component';
import { RedMotorsOpcionesFinanciamientoComponent } from './componentes/red-motors-opciones-financiamiento/red-motors-opciones-financiamiento.component';
import { RedMotorsOpcionesFinanciamientoModificarComponent } from './componentes/red-motors-opciones-financiamiento-modificar/red-motors-opciones-financiamiento-modificar.component';
import { RedMotorsBuscadorProductosComponent } from './componentes/red-motors-buscador-productos/red-motors-buscador-productos.component';
import { RedMotorsAgregarProductosComponent } from './componentes/red-motors-agregar-productos/red-motors-agregar-productos.component';
import { RedMotorsDetalleProductosComponent } from './componentes/red-motors-detalle-productos/red-motors-detalle-productos.component';
import { RedMotorsModificarProductosComponent } from './componentes/red-motors-modificar-productos/red-motors-modificar-productos.component';
const routes: Routes = [
  
  {
    path: 'categoria',
    component: RedMotorsCategoriasComponent,
    canActivate: [
      MsalGuard
    ]
   
  },
  {
    path: 'estilo',
    component: RedMotorsEstilosComponent,
    canActivate: [
      MsalGuard
    ]
   
  },
  {
    path: 'marca',
    component: RedMotorsMarcasComponent,
    canActivate: [
      MsalGuard
    ]
   
  },
  {
    path: 'modelo',
    component: RedMotorsModelosComponent,
    canActivate: [
      MsalGuard
    ]
   
  },
  {
    path: 'moneda',
    component: RedMotorsMonedasComponent,
    canActivate: [
      MsalGuard
    ]
   
  },
  {
    path: 'financia',
    component: RedMotorsEntidadesFinanciamientoComponent,
    canActivate: [
      MsalGuard
    ]
   
  },
  {
    path: 'financiamiento',
    component: RedMotorsOpcionesFinanciamientoComponent,
    canActivate: [
      MsalGuard
    ]
   
  },
  {
    path: 'agregarFinanciamiento',
    component: RedMotorsOpcionesFinanciamientoAgregarComponent,
    canActivate: [
      MsalGuard
    ]
   
  },
  {
    path: 'modificarFinanciamiento/:codigo',
    component: RedMotorsOpcionesFinanciamientoModificarComponent,
    canActivate: [
      MsalGuard
    ]
   
  },
  {
    path: 'productos',
    component: RedMotorsBuscadorProductosComponent,
    canActivate: [
      MsalGuard
    ]
   
  },
  {
    path: 'agregarProductos',
    component: RedMotorsAgregarProductosComponent,
    canActivate: [
      MsalGuard
    ]
   
  },
  {
    path: 'detalle/:codigo',
    component: RedMotorsDetalleProductosComponent,
    canActivate: [
      MsalGuard
    ]
   
  },
  {
    path: 'modificarProductos/:codigo',
    component: RedMotorsModificarProductosComponent,
    canActivate: [
      MsalGuard
    ]
   
  },
  {
    // Needed for hash routing
    path: 'code',
    component: RedMotorsHomeComponent
  },
  {
    path: '',
    component: RedMotorsHomeComponent
  }
];
const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
