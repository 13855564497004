import { Component, OnInit,TemplateRef } from '@angular/core';
import { ProductosService } from 'src/app/servicios/productos.service';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SubirArchivoService } from 'src/app/servicios/subir-archivo.service';
@Component({
  selector: 'app-red-motors-detalle-productos',
  templateUrl: './red-motors-detalle-productos.component.html',
  styleUrls: ['./red-motors-detalle-productos.component.css']
})
export class RedMotorsDetalleProductosComponent implements OnInit {
  datos:any;
  modalRef!: BsModalRef;
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
  producto: any={}
  isCollapsed = false;
   // Variable to store shortLink from api response
 shortLink: string = "";
 loading: boolean = false; // Flag variable
 file: File = null; // Variable to store file
  constructor(private productoSvg:ProductosService,
    private route:ActivatedRoute,
    private modalService: BsModalService,
    private subirFile:SubirArchivoService) { }

  ngOnInit(): void {
    this.productoListar();
  }
  productoListar():void{
    this._status = 'b'
    this.productoSvg.listar(this.route.snapshot.params['codigo'])
    .subscribe(data => {
      this._status = 'c'
      this.datos=data;
      this.producto=Object.assign({}, this.datos[0])
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  irModificar(modificar: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modificar);

  }
   // On file Select
   onChange(event) {
    this.file = event.target.files[0];
}

// OnClick of button Upload
onUpload() {
    this.loading = !this.loading;
    if (this.file.type=="image/jpeg" || this.file.type=="image/jpg"){
    this.subirFile.upload(this.file,this.route.snapshot.params['codigo'])
    .subscribe(data=> {
      this._status = 'c'
      this.modalRef.hide();
      this.productoListar();
      
    }
    );
    
    }
    else{
      alert("El archivo no cumple con la extensión valida")
    }
}
}
