import { Component, OnInit } from '@angular/core';
import { GuidService } from 'src/app/servicios/guid.service';
import { EntidadesFinanciamientoService } from 'src/app/servicios/entidades-financiamiento.service';
import { MonedasService } from 'src/app/servicios/monedas.service';
import { OpcionesFinanciamientoService } from 'src/app/servicios/opciones-financiamiento.service';
import { HttpClient,HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
@Component({
  selector: 'app-red-motors-opciones-financiamiento-agregar',
  templateUrl: './red-motors-opciones-financiamiento-agregar.component.html',
  styleUrls: ['./red-motors-opciones-financiamiento-agregar.component.css']
})
export class RedMotorsOpcionesFinanciamientoAgregarComponent implements OnInit {
  _status: string = 'a'; //a: initial, b: working, c: ok, d: error
  errorHttp!: HttpErrorResponse;
entidades:any;
monedas:any;
opcion = {
  codigo:this.guidSvg.newGUID(),
  codigoEntidadesFinanciamiento:null,
  codigoMonedas:null,
  nombre:null,
  usadosPorcentajePrima:0,
  usadosPlazo:0,
  usadosTasaFija:0,
  usadosTasaPiso:0,
  seminuevosPorcentajePrimaAsalariados:0,
  seminuevosPorcentajePrimaIndependientes:0,
  seminuevosPlazo:0,
  seminuevosTasaFija:0,
  seminuevosTasaPiso:0,
 
};
  constructor(private entidadesSvg:EntidadesFinanciamientoService,
    private guidSvg:GuidService,
    private monedaSvg:MonedasService,
    private opcionesSvg:OpcionesFinanciamientoService,
    private location:Location) { }

  ngOnInit(): void {
    this.listarFinanciamiento();
    this.listarMoneda();
  }
  listarFinanciamiento():void{
    this._status = 'b'
    this.entidadesSvg.listar(null)
    .subscribe(data => {
      this._status = 'c'
      this.entidades=data;
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  listarMoneda():void{
    this._status = 'b'
    this.monedaSvg.listar(null)
    .subscribe(data => {
      this._status = 'c'
      this.monedas=data;
     }, error => {
      this._status = 'd'
        this.errorHttp=error;
        alert(error.statusText);
      });
  }
  agregarOpcion():void{
    this._status='b'
    this.opcionesSvg.agregar(this.opcion.codigo,this.opcion.codigoEntidadesFinanciamiento,this.opcion.codigoMonedas,
      this.opcion.nombre,this.opcion.usadosPorcentajePrima,this.opcion.usadosPlazo,this.opcion.usadosTasaFija,this.opcion.usadosTasaPiso,
      this.opcion.seminuevosPorcentajePrimaAsalariados,this.opcion.seminuevosPorcentajePrimaIndependientes,this.opcion.seminuevosPlazo,
      this.opcion.seminuevosTasaFija,this.opcion.seminuevosTasaPiso)
      .subscribe(data=> {
        this._status = 'c'
        alert("Registro éxitoso");
        this.goBack();
       },
         error => {
          this._status = 'd'
          this.errorHttp=error;
          alert(error.statusText);
        }); 
  }
  goBack(): void {
    this.location.back();
  }
}
